import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Americantrucksimulator = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #a68fec, #9277df, #a68fec, #9277df, #a68fec )",
        icons: "#a68fec",
        navClass: "ats",
      }}
      seo={{
        title: "American Truck Simulator",
        headerTitle: "ats",
        href: slug,
        lang: "en",
        ogImage: require("../../assets/img/portfolio/ats_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/ats/",
      }}
    >
      <PortfolioHeader className="img_ats" name="ats" height="720" />
      <section className="container-fluid ats_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>American Truck Simulator</h1>
              <ul>
                <li>Strona Internetowa</li>
                <li className="first">Kampania leadowa</li>
                <li>Banery reklamowe</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner text ">
              <p>
                <strong>Cel główny:</strong> Sprzedaż planowanego nakładu na
                pierwszy kwartał 2016 <br />
                <strong>Dodatkowe cele:</strong> Budowanie świadomości o grze w
                grupie potencjalnie zainteresowanych graczy, zebranie
                wartościowej bazy mailowej, zaangażowanie liderów opinii.
              </p>
              <p>
                Zaplanowaliśmy aplikację konkursową w której użytkownicy mogli
                wygrać edycje kolekcjonerskie gry American Truck Simulator oraz
                imienne plakaty ATS dla 20 najlepszych wraz z pozdrowieniami od
                znanego Youtubera i team'u ATS
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid ats_section_3">
        <div className="col-md-4 offset-md-1">
          <div className="inner">
            <p>
              Nasze posty edukowały użytkowników na temat gry, aby potem mogli
              odpowiedzieć na pytania z quizu konkursowego.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid ats_section_4">
        <div className="row">
          <div className="col-md-5">
            <img
              src={require("../../assets/img/portfolio/label_ats.png")}
              alt=""
              className="img-fluid img0"
            />
          </div>
          <div className="col-md-3 offset-md-1 col_z">
            <img
              src={require("../../assets/img/portfolio/img2_ats.png")}
              alt=""
              className="img-fluid img2"
            ></img>
            <img
              src={require("../../assets/img/portfolio/super_ats.png")}
              alt=""
              className="img-fluid icon1"
            ></img>
            <img
              src={require("../../assets/img/portfolio/wow_ats.png")}
              alt=""
              className="img-fluid icon3"
            ></img>
          </div>
          <div className="col-md-3 col_x">
            <img
              src={require("../../assets/img/portfolio/like_ats.png")}
              alt=""
              className="img-fluid icon2"
            ></img>

            <img
              src={require("../../assets/img/portfolio/img1_ats.png")}
              alt=""
              className="img-fluid img1"
            ></img>

            <img
              src={require("../../assets/img/portfolio/img3_ats.png")}
              alt=""
              className="img-fluid img3"
            ></img>
          </div>
        </div>
      </section>

      <section className="container-fluid ats_section_5">
        <div className="row">
          <div className="col-md-3">
            <img
              src={require("../../assets/img/portfolio/game_ats.png")}
              alt=""
              className="img-fluid game"
            />
          </div>
          <div className="col-md-3 col_3">
            {" "}
            <img
              src={require("../../assets/img/portfolio/rules_ats.png")}
              alt=""
              className="img-fluid rules"
            />
          </div>
          <div className="col-md-4 offset-md-1 col_1">
            <div className="inner">
              <p>
                Zarówno konkurs, jak i informacja o pre-orderze gry były
                promowane poprzez reklamy Facebook ADS.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid ats_section_6">
        <div className="row">
          <div className="col-md-5 offset-md-1 col_2">
            <div className="inner">
              <p>
                Nawiązaliśmy ścisłą współpracę z Youtuberem "Wujkiem Bohunem",
                który:
              </p>
              <ul>
                <li>promował grę w środowisku swoich fanów;</li>
                <li>pomagał tworzyć pytania do quizu;</li>
                <li>publikował aktualne informacje na swoim fanpage'u;</li>
                <li>sygnował swoim imieniem plakaty dla graczy;</li>
              </ul>
            </div>
          </div>
          <div className="col-md-5">
            <img
              src={require("../../assets/img/portfolio/page_ats.png")}
              alt=""
              className="img-fluid page"
            />
          </div>
        </div>
      </div>

      <div className="container-fluid ats_section_7">
        <div className="row">
          <div className="col-md-3">
            <img
              src={require("../../assets/img/portfolio/yt_ats.png")}
              alt=""
              className="img-fluid yt"
            ></img>
          </div>
          <div className="col-md-3 hide">
            <img
              src={require("../../assets/img/portfolio/fp_ats.png")}
              alt=""
              className="img-fluid fp"
            ></img>
          </div>

          <div className="col-md-6 offset-md-5">
            <div className="inner">
              <p>W ciągu 2 miesięcy kampania zanotowała:</p>
              <ul>
                <li>
                  18 mln wyświetleń za pomoca sieci reklamowej Google AdWords;
                </li>
                <li>
                  Znaczny wzrost liczby fanów na fanpage'u Świata Symulatorów;
                </li>
                <li>Sprzedano cały nakład gry planowany na pierwszy kwartał</li>
                <li>
                  Rozpoczęcie kampanii promocyjnej nowego dodatku do gry
                  bezpośrednio z dystrybutorem
                </li>
                <li>
                  W ciągu dwóch pierwszych tygodni prowadzenia kampanii udało
                  się zrealizować cele zaplanowane na całe 2 miesiące trwania
                  kampanii.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Americantrucksimulator;
